import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AtributoReceitaEntity } from '@catalogo/models';
import { ObterPorId,ObterPorCodigo } from '../atributo-receita-store/atributo-receita.actions';

@Injectable({ providedIn: 'root' })
export class AtributoReceitaFormResolver implements Resolve<AtributoReceitaEntity> {
    constructor(private store: Store) { }

    resolve({ params, queryParams }: ActivatedRouteSnapshot): Observable<AtributoReceitaEntity> {
        const { id } = params;
        if(queryParams?.buscarPorCodigo){
            return this.store.dispatch(new ObterPorCodigo(id));
        }else{
            return this.store.dispatch(new ObterPorId(id));
        }
    }
}

