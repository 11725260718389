import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AtributoReceitaEntity } from '@catalogo/models';
import { AtributoReceitaDataService } from "@catalogo/services/data-services/atributo-receita.data.service";
import { Action, State, StateContext } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { ApiResult, FiltroSelecionado, ListaPaginada, Meta, NotificationService } from 'ui-shared';
import * as actions from './atributo-receita.actions';

export class AtributoReceitaStateModel {
    itens?: AtributoReceitaEntity[];
    selecionado?: AtributoReceitaEntity;
    meta?: Meta;
    filtro?: string;
    modalRemover?: string;
    filtrosSelecionados?: FiltroSelecionado[];
}

@State<AtributoReceitaStateModel>({
    name: 'AtributosReceita',
    defaults: {
        itens: [],
        meta: Meta.padrao
    }
})
@Injectable({ providedIn: 'root' })
export class AtributoReceitaState {

    constructor(
        private atributoReceitaDataService: AtributoReceitaDataService,
        public dialog: MatDialog,
        private notification: NotificationService,
        private ngZone: NgZone
    ) { }

    @Action(actions.ObterTodos)
    public getAll({ getState, patchState }: StateContext<AtributoReceitaStateModel>, { payload }: actions.ObterTodos) {
        let meta = { ...(payload?.meta || getState().meta) };
        
        meta.filtro = "";

        if (getState().filtro != null) {
            meta.filtro = meta.filtro === "" ? getState().filtro : `${meta.filtro} AND ${getState().filtro}`;
        }

        return this.atributoReceitaDataService.getWithQuery(meta)
            .pipe(
                map(({ data }: ApiResult<ListaPaginada<AtributoReceitaEntity>>) =>
                    patchState({
                        itens: data.itens,
                        selecionado: undefined,
                        meta: {
                            ...meta,
                            pagina: data.pagina,
                            total: data.total,
                        }
                    })
                )
            );
    }

    @Action(actions.AtualizarFiltro)
    public getByFilter({ patchState, getState, dispatch }: StateContext<AtributoReceitaStateModel>, { payload }: actions.AtualizarFiltro) {
        const newMeta = { ...getState().meta, pagina: 1 } as Meta;

        if (payload) {
            patchState({
                filtro: payload.query,
                meta: newMeta,
                filtrosSelecionados: payload.filtros
            });
        } else {
            patchState({
                filtro: null,
                meta: newMeta,
                filtrosSelecionados: null
            });
        }

        return dispatch(new actions.ObterTodos({
            meta: {
                ...getState().meta,
                filtro: payload?.query || ''
            }
        }));
    }


    @Action(actions.ObterPorId)
    public obterPorId({ getState, patchState }: StateContext<AtributoReceitaStateModel>, { payload }: actions.ObterPorId) {
        return this.atributoReceitaDataService.getById(payload)
            .pipe(map(result => patchState({ selecionado: result.data })));
    }
    
    @Action(actions.ObterPorCodigo)
    public obterPorCodigo({ getState, patchState }: StateContext<AtributoReceitaStateModel>, { payload }: actions.ObterPorId) {
        return this.atributoReceitaDataService.obterPorCodigoPortalUnico(payload)
            .pipe(map(result => patchState({ selecionado: result.data })));
    }
}
