import { FiltroSubmit, Meta } from 'ui-shared';

export class ObterTodos {
    public static readonly type = '[AtributoReceita] Obter todos';
    constructor(public payload: { meta?: Meta }) { }
}

export class AtualizarFiltro {
    public static readonly type = '[AtributoReceita] Obter por filtro';
    constructor(public payload: FiltroSubmit) { }
}

export class ObterPorId {
    public static readonly type = '[AtributoReceita] Obter por id';
    constructor(public payload: string) { }
}

export class ObterPorCodigo {
    public static readonly type = '[AtributoReceita] Obter por codigo';
    constructor(public payload: string) { }
}
